<template>
  <v-row justify="end">
    <v-btn
      right
      color="primary"
      class= "mr-5 mb-5"
      dark
      @click.stop="dialog = true"
    >
      Entrada Bitácora
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-h5">
          Mensaje Bitácora
        </v-card-title>

        <v-textarea
          class='mr-3 ml-3'
          outlined
          label="Mensaje bitácora"
          v-model="mensaje"
        ></v-textarea>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="success"
            @click="setBitacora()"
          >
            Enviar
          </v-btn>

          <v-btn
            color="error"
            @click="dialog = false"
          >
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  props: ['lineaCreditoCliente_id', 'documentacionCliente_id'],
  data: () => ({
    mensaje: '',
    dialog: false,
  }),
  methods: {
    async setBitacora() {
      const querie = {
        method: 'POST',
        url: `${API_URL}api/bitacora/inserta`,
        data: {
          lineaCreditoCliente_id: this.lineaCreditoCliente_id,
          texto: this.mensaje,
          side: 'operador',
          color: 'warning',
          documentacionCliente_id: this.documentacionCliente_id || '',
        },
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const { data } = await axios(querie);
      console.log(data);
      this.mensaje = '';
      this.dialog = false;
      this.$router.push('#bitacora');
      this.$router.go(0);
    },
  },
};
</script>
