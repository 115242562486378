<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Total Iva</th>
          <th class="text-left">Total</th>
          <th class="text-left">Reintegro Billetera</th>
          <th class="text-left">Reintegro Efectivo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>$ {{ total !== '' ? total : totalsub }}</td>
          <td>$ {{ total !== '' ? total : totalsub }}</td>
          <td>$ {{ total !== '' ? total : totalsub }}</td>
          <td>$ {{ total !== '' ? total : totalsub }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: ['total', 'totalsub'],
};
</script>
