<template>
  <div>
    <v-row>
      <v-col>
        <v-simple-table class="cc" :loading="loading">
          <thead>
            <tr class="text-center">
              <td>Cod. Crédito:</td>
              <td>Tipo Morosidad</td>
              <td>Saldo Mora</td>
              <td>Monto Solicitado</td>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" :style="{ 'font-weight': 'bolder' }">
              <td>
                {{ this.datCredito.legajo }}
              </td>
              <td :style="diasStyle(this.mayorDiasAtraso)">
                {{ tipoMorosidad(this.mayorDiasAtraso) }}
              </td>

              <td>${{ this.datCredito.SaldoMora }}</td>
              <td>$ {{ this.datCredito.MontoSolicitado }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table class="cc" :loading="loading">
          <thead>
            <tr class="text-center">
              <td>Nro. Cuota</td>
              <td>Fecha Vto.</td>
              <td>Comprobantes Asociados</td>
              <td>Capital</td>
              <td>Interes comp.</td>
              <td>Impuestos</td>
              <td>Gasto</td>
              <td>Monto Cuota</td>
              <td>Interes Moratorio</td>
              <td>Cobrado</td>
              <td>Fecha Cobro</td>
              <td>Dias de mora</td>
              <td>Saldo Teórico</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="15" class="text-center" v-if="loading">
                Cargando...
              </td>
            </tr>
            <tr>
              <td
                colspan="15"
                class="text-center"
                v-if="cargos.length < 1 && !loading"
              >
                No hay resultados
              </td>
            </tr>
            <tr
              class="text-center"
              v-for="cargo in cargos[0]"
              :key="cargo.id"
              :style="rowStyle(cargo.MontoCuota, cargo.cobrado)"
            >
              <td>{{ cargo.nrocuota }}</td>
              <td>{{ cargo.fecha }}</td>
              <td>
                <a v-if="cargo.link" target="_blank" :href="cargo.link">{{
                  cargo.CbtesAsociados
                }}</a>
              </td>
              <td>$ {{ cargo.capital }}</td>
              <td>$ {{ cargo.interes }}</td>
              <td>$ {{ cargo.impuestos }}</td>
              <td>$ {{ cargo.Gasto }}</td>
              <td :style="{ 'background-color': '#f2f2f2' }">
                $ {{ cargo.MontoCuota }}
              </td>
              <td>$ {{ cargo.interesMoratorio }}</td>
              <td>$ {{ cargo.cobrado }}</td>
              <td>{{ cargo.fechaImputacion }}</td>
              <td>
                {{ cargo.diasatraso }}
              </td>
              <td>$ {{ cargo.Saldo }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="2" style="padding-right: 25px">
        <br />
        <v-btn
          color="primary"
          :loading="loadingExcel"
          @click="exportarExcel()"
          block
          >Exportar Excel</v-btn
        >
        <br />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '@/common/config';
import ModalGastos from '@/components/Gastos/ModalGastos.vue';
import xlsx from 'xlsx';

export default {
  components: {
    ModalGastos,
  },
  name: 'CuentaCorriente',
  data: () => ({
    loadingExcel: false,
    loadingResetPagos: false,
    nro_cuota_sel: 0,

    loadingTotales: false,
    monto: 0,
    menu: '',
    fecha_desde: '',
    mora: 0,
    fecha_pago: '',
    cargos: [],
    mayorDiasAtraso: 0,
    datCredito: {},
    loading: false,
    promise: [],
    header: {
      headers: {
        token: localStorage.getItem('token'),
      },
    },
  }),
  props: ['solicitud_id', 'fecha_actual', 'fecha_ini', 'fecha_fin'],

  methods: {
    diasStyle(dias) {
      const style = { background: '', 'font-weight': 700 };

      if (dias <= 60) {
        style.background = '#E6FAA4';
      } else if (dias > 60 && dias <= 90) {
        style.background = '#FFE9AF';
      } else if (dias > 90 && dias <= 180) {
        style.background = '#FFB238';
      } else if (dias > 180 && dias <= 365) {
        style.background = '#FFC4B0';
      } else if (dias > 365) {
        style.background = '#B71D37';
      }

      return style;
    },
    rowStyle(monto, cobrado) {
      const style = { color: '', 'font-weight': 700 };

      if (monto <= cobrado) {
        style.color = '#021302';
      } else {
        style.color = '#800000';
      }

      return style;
    },
    tipoMorosidad(dias) {
      if (typeof dias !== 'number') {
        throw new Error("El parámetro 'dias' debe ser un número");
      }
      return dias <= 60
        ? 'Normal'
        : dias > 60 && dias <= 90
        ? 'Inadecuado'
        : dias > 90 && dias <= 180
        ? 'Con problemas'
        : dias > 180 && dias <= 365
        ? 'Alto riesgo'
        : dias > 365
        ? 'Incobrable'
        : '';
    },

    async getDesembolsos() {
      const formatter = new Intl.NumberFormat('es-AR', {
        maximumFractionDigits: 2,
      });
      if (!this.fecha_ini) {
        return false;
      }
      this.loading = true;
      const url = `${API_URL}api/trilay/ctacte`;
      let hasta = this.fecha_actual;

      const inicio = this.fecha_ini.replace(/-/g, '/');
      const fecha = moment(inicio, 'DD/MM/YYYY');
      const hoy = moment();
      const fechaIni = fecha.format('DD/MM/YYYY');
      const fechaHoy = hoy.format('DD/MM/YYYY');

      const data = {
        desde: fechaIni,
        hasta: hoy.isAfter(fecha) ? fechaHoy : '',
        legajo: this.solicitud_id,
      };
      await axios.post(url, data, this.header).then((r) => {
        const desembolsos = r.data.datosLegajo.map((c) => ({
          CodCredito: c.CodCredito,
          nrocomprobante: c.nrocomprobante,
          nrocuota: c.nrocuota,
          fecha: c.fecha,
          CbtesAsociados: c.CbtesAsociados,
          capital: formatter.format(c.capital.toFixed(2)),
          interes: formatter.format(c.interes.toFixed(2)),
          interesMoratorio: formatter.format(c.interesMoratorio.toFixed(2)),
          Gasto: formatter.format(c.Gasto.toFixed(2)),
          impuestos: formatter.format(c.impuestos.toFixed(2)),
          cobrado: formatter.format(c.cobrado.toFixed(2)),
          MontoCuota: formatter.format(c.MontoCuota.toFixed(2)),
          CodCreditoCuota: c.CodCreditoCuota,
          Vencida: c.Vencida,
          Seguros: c.Seguros,
          Cliente: c.Cliente,
          MontoSolicitado: formatter.format(c.MontoSolicitado.toFixed(2)),
          Jerarquia: c.Jerarquia,
          fechaImputacion: c.fechaImputacion,
          CodAdelanto: c.CodAdelanto === 'NULL' ? '--' : c.CodAdelanto || '0',
          link: c.link,
          Saldo: formatter.format(c.saldo.toFixed(2)),
          nrodoc: c.nrodoc,
          legajo: c.legajo,
          diasatraso: c.diasatraso,
          id: c.nrocuota,
        }));
        this.datCredito.SaldoMora = desembolsos
          .filter((c) => c.diasatraso > 0)
          .map((c) => parseInt(c.MontoCuota.replace('.', '')))
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        if (desembolsos.length > 0) {
          this.datCredito.legajo = desembolsos[0].nrocomprobante;
          this.datCredito.cliente = desembolsos[0].Cliente;
          this.datCredito.nrodoc = desembolsos[0].nrodoc;
          this.datCredito.Jerarquia = desembolsos[0].Jerarquia;
          this.datCredito.CodCredito = desembolsos[0].CodCredito;
          this.datCredito.MontoSolicitado = desembolsos[0].MontoSolicitado;
          // this.datCredito.SaldoCapital =
          //   desembolsos[desembolsos.length - 1].Saldo;
          this.cargos.push(desembolsos);
        }
        this.mayorDiasAtraso = desembolsos.reduce(
          (max, c) => {
            return c.diasatraso > max.diasatraso ? c : max;
          },
          { diasatraso: 0 }
        ).diasatraso;

        this.loading = false;
      });
    },

    async exportarExcel() {
      this.loadingExcel = true;
      let list = this.cargos[0].map((cc) => ({
        nrocuota: cc.nrocuota,
        fecha: cc.fecha,
        CbtesAsociados: cc.CbtesAsociados,
        capital: cc.capital,
        interés: cc.interes,
        impuestos: cc.impuestos,
        Gasto: cc.Gasto,
        MontoCuota: cc.MontoCuota,
        interésMoratorio: cc.interesMoratorio,
        cobrado: cc.cobrado,
        fechaCobro: cc.fechaImputacion,
        dísasMora: cc.diasatraso,
        SaldoTeórico: cc.Saldo,
        MontoSolicitado: cc.MontoSolicitado,
      }));
      let nombre = `${this.datCredito.cliente}-${this.datCredito.legajo}`;

      const cs = xlsx.utils.json_to_sheet(list);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, cs, 'Cta.cte');
      xlsx.writeFile(wb, `${nombre}.xlsx`);
      this.loadingExcel = false;
    },
  },
  created() {
    this.getDesembolsos();
  },
};
</script>
<style>
.cc thead {
  font-weight: 700;
}
.cc thead td {
  background-color: #f2f2f2;
  font-size: 12px !important;
}
.cc td {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  font-size: 12px !important;
  padding: 0 5px !important;
}
</style>
