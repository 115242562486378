<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
              <th v-if="!verFlag">
                <v-checkbox @click="selAll(comprobante)" ></v-checkbox>
              </th>
              <th class='text-left'>
              Tipo
              </th>
              <th class='text-left'>
              N° Comprobante
              </th>
              <th class='text-left'>
              Concepto
              </th>
              <th class='text-left'>
              Total Neto
              </th>
              <th class='text-left'>
              Total
              </th>
              <th class='text-left'>
              Opciones
              </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="comprobantes.length == 0">
            <td class="text-center" colspan="6">No hay documentos cargados</td>
          </tr>
          <tr
            v-for='item in comprobante'
            :key='item.id' :ref="`r${item.id}`" >
            <td v-if="!verFlag">
              <v-checkbox
                v-model="item.sel"
                v-if="!item.rechazado  && item.tipo_comprobante"
                @click="selOne(comprobante)"
              ></v-checkbox>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.rechazado"
                    color="red dark-red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{item.motivo_rechazo}}</span>
              </v-tooltip>
            </td>
            <td :class="(item.rechazado ? 'red--text' : '')
            || (item.monto_truncado !== 0 ? 'blue--text' : '')"
            >{{ item.tipo_comprobante === 0 ?
              '' :
              listTipoFactura.filter((tp) => tp.value === item.tipo_comprobante)[0].text
            }}</td>
            <td :class="(item.rechazado ? 'red--text' : '')
            || (item.monto_truncado !== 0 ? 'blue--text' : '')">{{ item.nro_comprobante }}</td>
            <td :class="(item.rechazado ? 'red--text' : '')
            || (item.monto_truncado !== 0
            && item.concepto !== 'TOTALES' ? 'blue--text' : '')">{{ item.concepto }}</td>
            <td :class="(item.rechazado ? 'red--text' : '')
            || (item.monto_truncado !== 0
            && item.concepto !== 'TOTALES' ? 'blue--text' : '')
">
              $ {{ item.isNotaCred ? '-':'' }} {{ item.neto_iva }}
            </td>
            <td :class="(item.rechazado ? 'red--text' : '')
            || (item.monto_truncado !== 0
            && item.concepto !== 'TOTALES' ? 'blue--text' : '')">
              $ {{item.isNotaCred ? '-':''}} {{ item.total }}
            </td>
            <td>
            <v-btn v-on:click='verDoc(item.nombre)'
            v-if="item.tipo_comprobante"
            small
            color='success'> <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
            v-if="!verFlag && item.tipo_comprobante"
            small
            v-on:click='eliminar(item, item.id, item.tipo_comprobante, item.nro_comprobante)'
            color='error'> <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
            v-if="item.rechazado"
            small
            v-on:click='revalidar(item.id, item.tipo_comprobante, item.nro_comprobante)'
            color='info'> <v-icon>mdi-refresh</v-icon>
            </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { API_URL, TIPO_FACTURA } from '@/common/config';
import axios from 'axios';

export default {
  props: ['comprobantes', 'verFlag'],
  data: () => ({
    listTipoFactura: TIPO_FACTURA,
    header: {
      headers: {
        token: localStorage.getItem('token'),
      },
    },
    comprobante: [],
  }),
  methods: {
    async revalidar(id, tipo, numero) {
      const url = `${API_URL}api/comprobante/revalidar`;
      const data = {
        comprobante_id: id,
      };
      this.$swal.fire({
        title: `¿Restaurar Comprobante ${numero}?`,
        text: 'El comprobante perderá el estado RECHAZADO',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Restaurar',
        denyButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(url, data, this.header).then((r) => {
            if (r.data.status === 'error') {
              this.$swal.fire(r.data.message, '', 'error');
            } else {
              const foundIndex = this.comprobante.findIndex((x) => x.id === id);
              this.comprobante[foundIndex].rechazado = false;
              this.comprobante[foundIndex].id = 'asdasd';
              this.$swal.fire('Re-validar', r.data.message, 'success');
            }
          }).catch((e) => {
            this.$swal.fire('Ocurrió un error', e, 'error');
          });
        }
      });
    },
    selAll(array) {
      const check = this.comprobante.some((e) => e.sel === true);
      if (check) {
        this.comprobante = array.map((c) => ({
          ...c,
          sel: false,
        }));
      } else {
        this.comprobante = array.map((c) => ({
          ...c,
          sel: true,
        }));
      }
      const comprobantesSinTotales = this.comprobante.filter((el) => el.concepto !== 'TOTALES');
      this.$emit('chkComprobantes', comprobantesSinTotales);
    },
    selOne(array) {
      this.$emit('chkComprobantes', array);
    },
    verDoc(archivo) {
      const docUrl = `${API_URL}comprobante/${archivo}`;
      window.open(docUrl);
    },
    eliminar(comprobante, id, tipo, numero) {
      const url = `${API_URL}api/comprobantes/eliminar/${id}`;
      this.$swal.fire({
        title: `Esta seguro que desea eliminar el Comprobante ${tipo}-${numero}`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Eliminar',
        denyButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.get(url, this.header).then((r) => {
            if (r.data.status === 'error') {
              this.$swal.fire(r.data.message, '', 'error');
            } else {
              this.$swal.fire('Comprobante Eliminado', '', 'success');
            }
            this.comprobantes = this.comprobantes.filter((c) => c.id !== id);
          }).catch((e) => {
            this.$swal.fire(e, '', 'error');
          });
        }
      });
    },
    totales() {
      const validos = this.comprobante.filter((el) => el.motivo_rechazo === '');
      const totalIva = validos.reduce((a, b) => {
        switch (b.tipo_comprobante) {
          case 3:
          case 8:
          case 13:
          case 21:
          case 38:
          case 53:
            return a - (parseFloat(b.neto_iva) || 0);
          default:
            return a + (parseFloat(b.neto_iva) || 0);
        }
      }, 0);
      const sumTotales = validos.reduce((a, b) => {
        switch (b.tipo_comprobante) {
          case 3:
          case 8:
          case 13:
          case 21:
          case 38:
          case 53:
            return a - (parseFloat(b.total) || 0);
          default:
            return a + (parseFloat(b.total) || 0);
        }
      }, 0);
      const totales = {
        concepto: 'TOTALES',
        neto_iva: totalIva.toFixed(2),
        total: sumTotales.toFixed(2),
        tipo_comprobante: 0,
      };
      this.comprobante.push(totales);
    },
  },
  created() {
    this.comprobante = this.comprobantes.map((el) => {
      switch (el.tipo_comprobante) {
        case 3:
        case 8:
        case 13:
        case 21:
        case 38:
        case 53:
          return { ...el, isNotaCred: true };
        default:
          return { ...el, isNotaCred: false };
      }
    });
    this.totales();
  },
};
</script>
