<template>
  <v-dialog v-model="dialog" persistent :max-width="550">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="warning"
        style="margin-top: 15px"
        dark
        block
        v-bind="attrs"
        v-on="on"
      >
        Generar Gasto
      </v-btn>
    </template>
    <v-card :loading="loading">
      <v-card-title class="text-h6"> Nuevo Gasto </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :lg="iva_flag ? 6 : 12">
            <v-text-field
              type="number"
              prepend-inner-icon="mdi-currency-usd"
              label="Monto"
              @keyup="calcularIVA"
              v-model="monto"
            >
            </v-text-field>
          </v-col>
          <v-col lg="6" v-if="iva_flag">
            <v-text-field
              type="number"
              v-model="iva"
              prepend-inner-icon="mdi-currency-usd"
              label="IVA"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-col>
          <v-btn-toggle v-model="tipo_gasto" borderless color="primary">
            <v-btn value="otorgamiento">
              <span class="hidden-sm-and-down">Otorgamiento</span>
            </v-btn>

            <v-btn value="posteriores" disabled>
              <span class="hidden-sm-and-down">Posteriores</span>
            </v-btn>

            <v-btn value="iibb">
              <span class="hidden-sm-and-down">IIBB</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <v-btn-toggle
            v-if="tipo_gasto !== 'iibb'"
            v-model="iva_flag"
            borderless
            color="green"
          >
            <v-btn :value="false">
              <span class="hidden-sm-and-down">s/IVA</span>
            </v-btn>

            <v-btn :value="true" @click="calcularIVA">
              <span class="hidden-sm-and-down">c/IVA</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          :disabled="monto <= 0 || tipo_gasto === ''"
          @click="guardarGasto"
        >
          Cargar
        </v-btn>
        <v-btn color="red darken-1" text @click="cerrarModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  name: 'ModalGastos',
  props: ['lineacreditocliente_id', 'idDesembolso'],
  data: () => ({
    tipo_gasto: '',
    monto: 0,
    iva: 0,
    loading: false,
    iva_flag: false,
    dialog: false,
    header: {
      headers: {
        token: localStorage.getItem('token'),
      },
    },
  }),
  methods: {
    async cerrarModal() {
      this.dialog = false;
    },
    calcularIVA() {
      this.iva = parseFloat(this.monto * 0.21).toFixed(2);
    },
    async guardarGasto() {
      this.loading = true;
      const url = `${API_URL}api/gastos/generar`;
      const data = {
        lineacreditocliente_id: this.lineacreditocliente_id,
        otorgamiento: 0,
        posteriores: 0,
        iva: 0,
        iibb: 0,
        idDesembolso: this.idDesembolso ? this.idDesembolso : null,
      };
      data[this.tipo_gasto] = parseFloat(this.monto);
      if (this.iva_flag) {
        data.iva = parseFloat(this.iva);
      }
      await axios
        .post(url, data, this.header)
        .then((result) => {
          this.loading = false;
          this.$swal({
            title: 'Gasto nuevo',
            text: 'El gasto se generó con exito',
            type: 'success',
            timer: 2000, // Set timer to 2 seconds (2000 milliseconds)
          });
          this.monto = 0;
          this.iva = 0;
          this.iva_flag = false;
          this.$emit('creditoLiquidado');
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$swal({
            title: 'Gasto nuevo',
            text: 'Ocurrió un error al generar el gasto',
            type: 'error',
            timer: 2000,
          });
          // this.$router.go(0);
        });
    },
  },
};
</script>
