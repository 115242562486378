<template>
  <v-btn @click="eliminarLegajos"
    class="error" block
  >
      Eliminar Liquidaciones
  </v-btn>
</template>
<script>
/* eslint-disable camelcase */
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Legajo',
  props: ['legajo'],
  methods: {
    async eliminarLegajos() {
      this.$swal.fire({
        title: '¿Esta seguro?',
        text: 'Usted desea eliminar las liquidaciones!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#007e33',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminalas!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const query = {
            method: 'post',
            url: `${API_URL}api/liquidacion/reiniciar`,
            data: {
              lineacreditocliente_id: this.legajo,
            },
            headers: {
              token: localStorage.getItem('token'),
            },
          };
          const { data: { status, message } } = await axios(query);
          if (status === 'error') console.error(message);
          if (status === 'success') {
            await this.$swal.fire(
              'Eliminadas!',
              message,
              'success',
            );
            this.$router.go(0);
          }
        }
      });
    },
  },
};
</script>
