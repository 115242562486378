<template>
  <v-row justify="end">
    <v-btn
      right
      color="primary"
      class= "mr-5 mb-5"
      @click="guardarClienteTrilay"
      :loading="loadSync"
    >
      Sincronizar Cliente
    </v-btn>
  </v-row>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  props: ['lineaCreditoCliente_id'],
  data: () => ({
    loadSync: false,
  }),
  methods: {
    async guardarClienteTrilay() {
      this.loadSync = true;
      const url = `${API_URL}api/clientes/trilay/insertar`;
      const cpurl = `${API_URL}api/clienteParametro/format`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const d = {
        lineacreditocliente_id: this.lineaCreditoCliente_id,
      };
      await axios.post(cpurl, d, header).then(async (re) => {
        const data = { lcc_id: this.lineaCreditoCliente_id, parametros: re.data.result };
        await axios.post(url, data, header).then((r) => {
          console.log(r.data);
          const CODCLIENTE = r.data.codigo_cliente_trilay;
          console.log('Cliente Creado en trilay', CODCLIENTE);
          if (r.data.flag) {
            this.$swal.fire('Cliente creado con exito en Trilay', `El cliente se generó en trilay con el código ${CODCLIENTE}`, 'success');
          } else {
            this.$swal.fire('El Cliente ya existe', `Código: ${CODCLIENTE}`, 'success');
          }
          this.loadSync = false;
        }).catch((e) => {
          this.$swal.fire('Error al sincronizar en Trilay', e, 'error');
          this.loadSync = false;
        });
      });
    },
    // Crear funcion que valide si existe el cliente en trilay
  },
};
</script>
