<template>
  <div>
    <v-simple-table v-if="datos.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">N°</th>
            <th class="text-left">Fecha</th>
            <th class="text-left">Saldo Inicial</th>
            <th class="text-left">Amortización</th>
            <th class="text-left">Intereses</th>
            <th class="text-left">Cuota</th>
            <th class="text-left">Saldo Final</th>
            <th class="text-left">IVA Intereses</th>
            <th class="text-left">Cuota Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in datos" :key="index">
            <td>{{ parseInt(item[1].N_Cuota) }}</td>
            <td>{{ item[0] }}</td>
            <td>
              $
              {{
                formatter.format(parseFloat(item[1].Saldo_Inicial).toFixed(2))
              }}
            </td>
            <td>
              ${{
                formatter.format(parseFloat(item[1].Amortizacion).toFixed(2))
              }}
            </td>
            <td>
              ${{ formatter.format(parseFloat(item[1].Intereses).toFixed(2)) }}
            </td>
            <td>
              ${{ formatter.format(parseFloat(item[1].Cuota).toFixed(2)) }}
            </td>
            <td>
              ${{
                formatter.format(parseFloat(item[1].Saldo_Final).toFixed(2))
              }}
            </td>
            <td>
              ${{
                formatter.format(parseFloat(item[1].IVA_Intereses).toFixed(2))
              }}
            </td>
            <td>
              ${{
                formatter.format(parseFloat(item[1].Cuota_Total).toFixed(2))
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-container>
      <v-row>
        <v-col md="4" offset-md="8">
          <v-btn
            v-if="data || datos"
            color="black"
            text
            @click="exportPDF('0')"
          >
            Imprimir
          </v-btn>
          <v-btn v-if="data || datos" color="blue" text @click="exportPDF('1')">
            Descargar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import logo from '@/common/logo';
import axios from 'axios';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
// import moment from 'moment';

export default {
  props: ['datos', 'cliente', 'datosParametros', 'solicitud_id', 'operatoria'],
  name: 'CuadroMarcha',
  data: () => ({
    data: [],
    token: localStorage.getItem('token'),
    tasa: '',
    monto: '',
    pagos: '',
    cuotas: '',
    periodo: '',
    fecha: '',
    formatter: new Intl.NumberFormat('es-AR', {
      maximumFractionDigits: 2,
    }),
  }),
  methods: {
    exportPDF(tipo) {
      const tasa = parseFloat(
        this.datosParametros.tipoParametros.find((p) => p.clave === 'tasa')
          .value[0].valor,
        10
      );
      const fb = parseFloat(
        this.datosParametros.tipoParametros.find(
          (p) => p.clave === 'factor_bonificacion'
        ).value[0].valor,
        10
      );
      let height = 66;
      if (this.datos.length < 1) {
        return;
      }
      const doc = new JsPDF();
      const columns = [
        { title: 'Cuota', dataKey: 'Cuota' },
        { title: 'Fecha', dataKey: 'Fecha' },
        { title: 'Saldo Inicial', dataKey: 'Saldo_Inicial' },
        { title: 'Amortización', dataKey: 'Amortizacion' },
        { title: 'Intereses', dataKey: 'Intereses' },
        { title: 'Saldo Final', dataKey: 'Saldo_Final' },
        { title: 'IVA Intereses', dataKey: 'IVA_Intereses' },
        { title: 'Cuota Total', dataKey: 'Cuota_Total' },
      ];
      const data = JSON.parse(JSON.stringify(this.datos));
      const result = [];
      Object.values(data).forEach((key) => {
        result.push({
          Cuota: parseInt(key[1].N_Cuota, 10),
          Amortizacion: parseFloat(key[1].Amortizacion).toFixed(2),
          Cuota_Total: parseFloat(key[1].Cuota_Total).toFixed(2),
          Fecha: key[0],
          IVA_Intereses: parseFloat(key[1].IVA_Intereses).toFixed(),
          Intereses: parseFloat(key[1].Intereses).toFixed(2),
          Saldo_Final: parseFloat(key[1].Saldo_Final).toFixed(2),
          Saldo_Inicial: parseFloat(key[1].Saldo_Inicial).toFixed(2),
        });
      });
      // const date = moment((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
      // .toISOString().substr(0, 10)).format('DD-MM-YYYY');
      const apellido = `${this.cliente.nombre} ${this.cliente.apellido}`;
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.line(15, 23, 200, 23, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('Desarrollo del Crédito', 15, 30);
      doc.text(this.fecha, 150, 30);
      doc.text(`Nombre: ${apellido}`, 15, 37);
      // doc.setLineDash([10, 10], 0);
      doc.line(15, 39, 200, 39, 'FD');
      doc.setFontSize(15);
      doc.text(`Monto: $ ${parseFloat(this.monto).toFixed(2)}`, 15, 45);
      doc.text(`Plazo: ${this.plazo}`, 70, 45);
      doc.text(`Tasa: ${tasa}%`, 105, 45);
      doc.text(`Período: ${this.cuotas}`, 145, 45);
      doc.text(`Factor Bonif: ${parseInt(fb, 10)}`, 15, 52);
      doc.text(`Período de Gracia: ${parseInt(this.periodo, 10)}`, 70, 52);
      const splitTitle = doc.splitTextToSize(this.operatoria.nombre, 180);
      doc.text(`${splitTitle[0]}`, 15, 59);
      if (doc.getTextWidth(this.operatoria.nombre) >= 200) {
        doc.text(`${splitTitle[1]}...`, 15, 66);
        height = 73;
      }
      doc.autoTable(columns, result, {
        startY: height,
        margin: { horizontal: 7 },
        styles: { cellWidth: 'wrap', halign: 'center' },
        columnStyles: { text: { cellWidth: 'auto' } },
      });
      if (tipo === '0') {
        window.open(doc.output('bloburl'), '_blank');
      } else {
        doc.save(`Desarrollo del Crédito ${apellido}.pdf`);
      }
    },
    getParametrosCliente() {
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/clienteParametros`;
      const data = {
        lineaCreditoCliente_id: parseInt(this.solicitud_id, 10),
      };
      axios.post(url, data, header).then((result) => {
        // console.log(result);
        this.plazo = result.data.ClienteParametro.find(
          (r) => r.tipo_parametro_id.clave === 'plazo_credito'
        ).valor;
        this.monto = result.data.ClienteParametro.find(
          (r) => r.tipo_parametro_id.clave === 'monto'
        ).valor;
        this.periodo = result.data.ClienteParametro.find(
          (r) => r.tipo_parametro_id.clave === 'periodo_gracia'
        ).valor;
        this.cuotas = result.data.ClienteParametro.find(
          (r) => r.tipo_parametro_id.clave === 'periodicidad_pagos'
        ).valor;
        this.fecha = result.data.ClienteParametro.find(
          (r) => r.tipo_parametro_id.clave === 'fecha_inicio'
        ).valor;
      });
      return true;
    },
    cargarCuadro() {
      const url = `${API_URL}api/historicoCuadroMarcha/${this.$route.params.id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      axios.get(url, header).then((result) => {
        this.data = result.data.historicoCuadro;
      });
    },
  },
  created() {
    this.cargarCuadro();
  },
  beforeMount() {
    this.getParametrosCliente();
  },
};
</script>
