<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" color="info" class="ml-1">
        <v-icon>mdi-book-edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Documentación: {{ documento.original.nombre }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <span>Descripción: {{ documento.original.descripcion }}</span>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                class="mt-2"
                name="input-7-4"
                label="Cuadro de observaciones"
                value="La documentación se encuentra aprobada.-"
                v-model="observaciones"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-file-input
                label="Adjuntar observaciones"
                v-model="imagen"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="enviarObs('aprobado')">
          Aprobar
        </v-btn>
        <v-btn color="red darken-1" text @click="enviarObs('rechazado')">
          Observar
        </v-btn>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Salir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Validacion',
  props: ['documento', 'operatoria'],
  data: () => ({
    isEditing: false,
    loaded: false,
    loading: false,
    dialog: false,
    imagen: null,
    observaciones: 'La documentación se encuentra aprobada.-',
  }),
  methods: {
    resetForm() {
      this.observaciones = 'La documentación se encuentra aprobada.-';
    },
    async enviarObs(estado) {
      const aux = estado === 'aprobado' ? 2 : 1;
      if (
        (aux === 1 &&
          this.observaciones == 'La documentación se encuentra aprobada.-') ||
        this.observaciones === ''
      ) {
        await this.$swal.fire(
          'Atención!!',
          'Debe ingresar una observación',
          'error'
        );
        return;
      }

      const url = `${API_URL}api/documentacionClientes/observaciones`;
      const documentacion = this.documento.cliente;
      const formData = new FormData();
      formData.append('observaciones', this.observaciones);
      formData.append('operatoria', this.operatoria);
      formData.append('cliente_id', documentacion.cliente_id);
      formData.append('documentacionCliente_id', documentacion.id);
      formData.append(
        'lineaCreditoCliente_id',
        this.documento.cliente.lineacreditocliente_id
      );
      formData.append('estado', aux);
      formData.append('isAdjunto', this.imagen === null ? false : true);
      formData.append('adjunto', this.imagen);
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .post(url, formData, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.loading = false;
            this.success = true;
            this.error = false;
            this.resetForm();
          } else {
            this.loading = false;
            this.error = true;
            this.errorMensaje = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
      this.dialog = false;
      this.$emit('revisado', 'documentos');
      this.$router.go(`${this.$router.currentRoute}#documentacion`);
    },
  },
};
</script>
