<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mr-5"
        v-if="operatoria !== 16"
        color="info"
        @click="registrosLegajo()"
      >
        Modificar Parametros
      </v-btn>
    </template>
    <v-card>
      <v-alert v-if="success" border="bottom" color="success" dark>
        {{ successMensaje }}
      </v-alert>
      <v-alert v-if="error" border="bottom" color="error" dark>
        {{ successError }}
      </v-alert>
      <v-container v-show="operatoria !== 6">
        <v-row>
          <v-col>
            <v-card-title>Parametros:</v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Cuil / Cuit del Beneficiario"
              v-model="cuit"
              type="number"
              pattern="/^[0-9]{11}$/"
              :rules="[
                (v) =>
                  /^[0-9]{11}$/.test(v) || 'El CUIT no tiene un formato válido',
                (v) =>
                  v.length <= 11 ||
                  'El CUIT no debe tener más de 11 caracteres',
              ]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Dni"
              v-model="dni"
              type="number"
              pattern="/^[0-9]{8}$/"
              :rules="[
                (v) =>
                  /^[0-9]{8}$/.test(v) || 'El DNI no tiene un formato válido',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="CBU"
              v-model="cbu"
              type="number"
              pattern="/^[0-9]{22}$/"
              :rules="[
                (v) =>
                  /^[0-9]{22}$/.test(v) || 'El CBU debe tener 22 caracteres',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col>
          <v-text-field
            label="Email"
            v-model="email"
            :rules="[
              (v) =>
                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
                'El email no tiene un formato válido',
            ]"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Movil" v-model="celular"></v-text-field>
        </v-col>
        <v-row>
          <v-col cols="8"></v-col>
          <v-col cols="2">
            <v-btn
              class="primary"
              @click="guardarRegistrosLegajo"
              :disabled="
                !/[0-9]{11}$/.test(cuit) ||
                cuit.length > 11 ||
                !/[0-9]{22}$/.test(cbu) ||
                cbu.length > 22
              "
            >
              Actualizar
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn color="error" @click="salir"> Cancelar </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-show="operatoria === 6">
        <v-row>
          <v-col>
            <v-card-title>Parametros Mendoza Activa:</v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Cuil / Cuit del Beneficiario"
              v-model="cuit"
              type="number"
              pattern="/^[0-9]{11}$/"
              :rules="[
                (v) =>
                  /^[0-9]{11}$/.test(v) || 'El CUIT no tiene un formato válido',
                (v) =>
                  v.length <= 11 ||
                  'El CUIT no debe tener más de 11 caracteres',
              ]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Numero Ticket"
              v-model="ticket"
              type="number"
              pattern="/^[0-9]{1,12}$/"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="CBU"
              v-model="cbu"
              type="number"
              pattern="/^[0-9]{22}$/"
              :rules="[
                (v) =>
                  /^[0-9]{22}$/.test(v) || 'El CBU debe tener 22 caracteres',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Monto ANR Aprobado"
              v-model="montoANR"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Inversión Aprobada"
              v-model="inversion"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8"></v-col>
          <v-col cols="2">
            <v-btn
              class="primary"
              @click="guardarRegistrosLegajo"
              :disabled="
                !/[0-9]{11}$/.test(cuit) ||
                cuit.length > 11 ||
                !/[0-9]{22}$/.test(cbu) ||
                cbu.length > 22
              "
            >
              Actualizar
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn color="error" @click="salir"> Cancelar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  data: () => ({
    loading: false,
    dialog: false,
    ticket: '',
    fideicomisoId: '',
    montoANR: '',
    cuit: '',
    email: '',
    celular: '',
    idCliente: '',
    dni: '',
    inversion: '',
    cbu: '',
    successMensaje: '',
    success: false,
    successError: '',
    error: false,
  }),
  props: ['legajoId', 'operatoria'],
  methods: {
    salir() {
      this.$router.go('/');
      this.dialog = false;
    },
    async registrosLegajo() {
      const query = {
        method: 'get',
        url: `${API_URL}api/clienteParametros/parametros/${this.legajoId}`,
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const { data } = await axios(query);
      this.fideicomisoId = data.fideicomiso_id;
      this.ticket = data.ticket;
      this.montoANR = data.montoANR;
      this.cbu = data.cbu;
      this.cuit = data.cuit;
      this.inversion = data.inversion;
      this.email = data.email;
      this.celular = data.celular;
      this.dni = data.dni;
      this.idCliente = data.idCliente;
    },
    async guardarRegistrosLegajo() {
      const query = {
        method: 'post',
        url: `${API_URL}api/clienteParametro/actualizarParametros`,
        headers: {
          token: localStorage.getItem('token'),
        },
        data: {
          idOperatoria: this.operatoria,
          id: this.legajoId,
          //mdza activa
          ticket: this.ticket,
          montoANR: this.montoANR,
          cbu: this.cbu,
          cuit: this.cuit,
          inversion: this.inversion,
          //financiamiento
          email: this.email,
          celular: this.celular,
          dni: this.dni,
          idCliente: this.idCliente,
        },
      };
      const { data } = await axios(query);
      if (data.status === 'success') {
        await this.$swal.fire('Actualizado', data.message, 'success');
        this.$router.go(0);
      } else if (data.status === 'error') {
        await this.$swal.fire('Algo salio mal!!', '', 'error');
        this.$router.go(0);
      }
    },
  },
};
</script>
