<template>
  <v-container>
    <div class="arrow-wrapper" v-if="this.legajos.length !== 0">
      <v-btn
        icon
        color="primary"
        class="arrow-button"
        @click="handleClickAtras()"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="legajo-container">
        <span class="title">Legajo:</span>
        <span class="title">{{ actual }}</span>
      </div>
      <v-btn
        icon
        color="primary"
        class="arrow-button"
        @click="handleClickAdelante()"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
    <Solicitud
      v-if="!isEjecutivo && operatoria.length !== 0"
      :cliente="cliente"
      :operatoria="operatoria"
      :documentos="documentos"
      :docsSinFiltrar="documentosSinFiltrar"
      :parametrosOperatoria="parametrosOperatoria"
      :estado_id="estado_id"
      :downloads="downloads"
      :legajo="actual"
    ></Solicitud>
    <Credito
      v-if="isEjecutivo && flagLoad"
      :cliente="cliente"
      :lineacredito_id="operatoria.id"
      :operatoria="operatoria"
      :solicitud_id="parseInt(actual, 10)"
      :tabs="tabsArr"
      :fechaDesembolso="fechaDesembolso"
      :legajo="Number(actual)"
    >
    </Credito>
  </v-container>
</template>
<script>
// import { API_URL } from '@/common/config';
// const axios = require('axios');
import Solicitud from '@/components/Credito/Solicitud.vue';
import Credito from '@/components/Credito/Credito.vue';

import { API_URL } from '@/common/config';
import axios from 'axios';

const _ = require('lodash');

export default {
  name: 'CreditoView',
  components: {
    Solicitud,
    Credito,
  },
  data: () => ({
    legajos: [],
    token: localStorage.getItem('token'),
    estado_id: '',
    isEjecutivo: '',
    tabsArr: [],
    cliente: {},
    operatoria: [],
    documentosSinFiltrar: [],
    documentos: [],
    flagLoad: false,
    parametrosOperatoria: [],
    downloads: [],
    estadoMora: '',
    fechaDesembolso: '',
  }),

  methods: {
    processDownloads(documentos) {
      this.downloads = documentos.filter(
        (d) => !d.rol.includes(localStorage.rolDescripcion) && d.url !== null
      );
    },
    getSolicitud() {
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios
        .get(`${API_URL}api/servicioClientes/${this.actual}`, header)
        .then((response) => {
          this.credito_id =
            response.data.lineaCreditoCliente[0].lineaCredito_id;
          this.cliente_id = response.data.lineaCreditoCliente[0].cliente_id;

          // eslint-disable-next-line
          const { acta, contrato, dictamen, informe_elevacion } =
            response.data.lineaCreditoCliente[0];
          this.processDownloads([
            {
              key: 'acta',
              title: 'Acta',
              url: acta,
              rol: ['Operador'],
            },
            {
              key: 'contrato',
              title: 'Contrato',
              url: contrato,
              rol: ['Operador'],
            },
            {
              key: 'dictamen',
              title: 'Dictamen',
              url: dictamen,
              rol: ['Operador'],
            },
            {
              key: 'informe_elevacion',
              title: 'Informe de Elevación',
              url: informe_elevacion,
              rol: ['Operador'],
            },
          ]);
          this.estado_id = response.data.lineaCreditoCliente[0].estado_id;
          this.getOperatoria();
          this.oldLegajo = this.legajo;
          this.getCliente();
          this.getDocumentacion();
        });
    },
    getOperatoria() {
      // Verifico si es un Fideicomiso o una Linea de Credito
      const url = `${API_URL}api/servicio/${this.credito_id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios
        .get(url, header)
        .then((response) => {
          this.operatoria = response.data.lineaCredito;
          this.flagLoad = true;
          this.getOperatoriaParametros();
        })
        .catch((error) => {
          console.error(`Error fetching operatoria: ${url}`, error);
        });
    },
    getDocumentacion() {
      const url = `${API_URL}api/documentacionClientes/servicio/${this.credito_id}/${this.actual}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        const rol = localStorage.getItem('rol');

        if (!response.data.listaDocumentosCliente) {
          console.log('No posee doc.--');
          this.documentos = [];
          return;
        }

        const docs = response.data.listaDocumentosCliente
          .filter((el) => el.documento_maestro)
          .map((el) => {
            let validationRol;

            if (rol === '5') {
              validationRol = el.documento_maestro.tipo_analisis === 'Legal';
            } else {
              validationRol = el.documento_maestro.tipo_analisis !== 'Legal';
            }
            return {
              ...el,
              validacionRol: validationRol,
            };
          });
        this.documentosSinFiltrar = docs;
        this.documentos = _.groupBy(
          docs,
          (d) => d.documento_maestro.tipo_analisis
        );
      });
    },
    getCliente() {
      const url = `${API_URL}api/clientes/${this.cliente_id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.cliente = response.data.cliente;
      });
    },
    getOperatoriaParametros() {
      const url = `${API_URL}api/servicioParametros/operatoria/${this.operatoria.id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios
        .get(url, header)
        .then((result) => {
          this.parametrosOperatoria = result.data.parametros;
        })
        .catch((e) => {
          console.error('CreditoView::getOperatoriaParametros');
          console.error(e);
        });
    },
    setTabs() {
      let tabsAux = [];
      if (this.isEjecutivo) {
        tabsAux = [
          'Legajo',
          'Parámetros de Crédito',
          'Liquidaciones',
          'Desarrollo del Crédito',
          'Cuenta Corriente',
        ];
      }
      this.tabsArr = tabsAux;
    },
    handleClickAtras() {
      const array = this.legajos;

      const index = array.indexOf(this.actual);

      if (index !== -1) {
        if (index === 0) {
          this.actual = array[0];
          return;
        }

        this.actual = array[index - 1];
        this.$router.push({
          name: 'Gestionar',
          params: {
            id: this.actual,
            legajos: array,
          },
        });
        this.getSolicitud();
      }
    },

    handleClickAdelante() {
      const array = this.legajos;

      const index = array.indexOf(this.actual);

      if (index !== -1) {
        if (index === array.length - 1) {
          this.actual = array[index];
          return;
        }

        this.actual = array[index + 1];
        this.$router.push({
          name: 'Gestionar',
          params: {
            id: this.actual,
            legajos: array,
          },
        });
        this.getSolicitud();
      }
    },
  },
  watch: {
    '$route.params.id'(legajo) {
      // Recarga la información del legajo
      this.actual = legajo;
      this.getSolicitud();
    },
    operatoria() {
      // Renderizar el componente
      this.$forceUpdate();
    },
  },
  created() {
    if (localStorage.getItem('rol') === '6') {
      this.isEjecutivo = true;
      this.setTabs();
    }
    this.legajos = this.$route.params.legajos ? this.$route.params.legajos : [];
    this.actual = this.$route.params.id;
    this.getSolicitud();
    this.estadoMora = this.$route.params.estadoMora;
    this.fechaDesembolso = this.$route.params.fechaDesembolso;
    this.estadoManual = this.$route.params.estadoManual;
    this.observacion = this.$route.params.observacion;
  },
};
</script>
<style>
.v-btn.arrow-button {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: rgb(239, 239, 239) !important;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
}
.legajo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
