var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"450px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"block":"","color":"warning"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.name)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"id":"myform","lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Monto","rules":_vm.montoRules,"required":""},model:{value:(_vm.monto),callback:function ($$v) {_vm.monto=$$v},expression:"monto"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Plazo","rules":_vm.plazoRules,"required":""},model:{value:(_vm.plazo),callback:function ($$v) {_vm.plazo=$$v},expression:"plazo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Periodo de gracia","rules":_vm.periodoRules,"required":""},model:{value:(_vm.periodo_gracia),callback:function ($$v) {_vm.periodo_gracia=$$v},expression:"periodo_gracia"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Tasa","disabled":""},model:{value:(_vm.tasa),callback:function ($$v) {_vm.tasa=$$v},expression:"tasa"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Periocidad de pagos","rules":[function (v) { return !!v || 'La periodicidad de pagos es obligatoria'; }],"items":[{value: 1, text: 'Mensual'},{value: 2, text: 'Bimestral'},
                {value: 3, text: 'Trimestral'},
                {value: 6, text: 'Semestral'}, {value: 12, text: 'Anual'}]},model:{value:(_vm.periodicidadPagos),callback:function ($$v) {_vm.periodicidadPagos=$$v},expression:"periodicidadPagos"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.fecha,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fecha=$event},"update:return-value":function($event){_vm.fecha=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formatDate),callback:function ($$v) {_vm.formatDate=$$v},expression:"formatDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Salir ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.fecha)}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.getRefinanc()}}},[_vm._v(" Refinanciar ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Salir ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }