<template>
  <div>
    <ListadoComprobantes
      :verFlag="true"
      :comprobantes="comprobantes"
      v-if="mostrarComprobantes"
    ></ListadoComprobantes>
    <v-simple-table>
      <template v-slot:default v-if="!mostrarComprobantes">
        <thead>
          <tr>
            <th class="text-left">Fecha</th>
            <th class="text-left">Cant. de comprobantes</th>
            <th class="text-left">Total Neto</th>
            <th class="text-left">Total</th>
            <th class="text-left">Reintegro Billetera</th>
            <th class="text-left">Reintegro Efectivo</th>
            <th class="text-left">Reintegro Credito Fiscal</th>
            <th class="text-left">Reintegro Total</th>
            <th class="text-left">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="liquidaciones.length == 0">
            <td class="text-center" colspan="5">No hay liquidaciones aun</td>
          </tr>
          <tr v-for="item in liquidaciones" :key="item.id">
            <td>{{ formatDate(item.createdAt) }}</td>
            <td>{{ item.comprobantes.length }}</td>
            <td>$ {{ item.total_iva.toFixed(2) }}</td>
            <td>$ {{ item.total.toFixed(2) }}</td>
            <td>$ {{ item.billetera.toFixed(2) }}</td>
            <td>$ {{ item.efectivo.toFixed(2) }}</td>
            <td>$ {{ item.credito_fiscal.toFixed(2) }}</td>
            <td>
              $
              {{
                (item.billetera + item.efectivo + item.credito_fiscal).toFixed(
                  2
                )
              }}
            </td>
            <td>
              <span v-if="!item.anticipo">
                <v-btn color="info" @click="verComprobantes(item.comprobantes)"
                  >Ver Comprobantes</v-btn
                >
              </span>
              <span v-if="item.anticipo">
                <v-alert type="info">ANTICIPO</v-alert>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      color="primary"
      class="mt-5 ml-5 mr-5 mb-5"
      block
      v-if="mostrarComprobantes"
      @click="mostrarComprobantes = false"
    >
      Volver al listado</v-btn
    >
  </div>
</template>
<script>
import moment from 'moment';
import ListadoComprobantes from '@/components/Comprobantes/ListadoComprobantes.vue';

export default {
  props: ['liquidaciones'],
  data: () => ({
    mostrarComprobantes: false,
    comprobantes: [],
  }),
  created() {
    console.log(this.liquidaciones);
  },
  components: {
    ListadoComprobantes,
  },
  methods: {
    verComprobantes(comp) {
      this.mostrarComprobantes = true;
      this.comprobantes = comp.map((c) => c.comprobante_id);
    },
    formatDate(f) {
      return f ? moment(f).format('DD-MM-YYYY') : '';
    },
  },
};
</script>
