<template>
  <v-btn @click="liquidarFinanciamiento" class="info" block> Liquidar </v-btn>
</template>
<script>
/* eslint-disable camelcase */
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'LiquidarBtn',
  props: ['legajo_id', 'monto', 'tipo_servicio'],
  methods: {
    async liquidarFinanciamiento() {
      const query = {
        method: 'post',
        url: `${API_URL}api/solicitud/liquidacionSubtipo`,
        data: {
          lineaCreditoCliente_id: this.legajo_id,
          concepto: 'Comprobante genérico para Financiamiento',
          neto: this.monto,
          servicio: this.tipo_servicio,
        },
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const {
        data: { status, message },
      } = await axios(query);
      if (status === 'success') {
        await this.$swal.fire({
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$emit('creditoLiquidado');
        this.$router.go(0);
      } else {
        await this.$swal.fire({
          icon: 'error',
          title: 'Algo salio mal!!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // console.log(message);
      // this.$router.go(`${this.$router.currentRoute}#TablaParametros`);
      //  this.$router.go(0);
    },
  },
};
</script>
