<template>
  <v-row justify="center">
    <v-dialog v-model="habilitar" persistent max-width="450px">
      <span class="text-h4">Agregar Desembolso</span>
      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-h5">Desembolso N°: {{ indice }}</span>
                <v-col cols="12">
                  <span class="font-weight-bold">Saldo Disponible: </span>
                  <span :class="{ 'red--text': saldo === 0 }">
                    {{ formatter.format(saldo) }}
                  </span>
                </v-col>
                <v-text-field
                  label="Monto Desembolsado"
                  v-model="monto"
                  required
                  :rules="[
                    (v) => (v || '') !== '' || 'El monto es obligatorio',
                  ]"
                  type="number"
                  v-bind:value="formatter.format(monto)"
                ></v-text-field>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="fecha"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      label="Fecha de inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fecha" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"
                      >Cancelar</v-btn
                    >
                    <v-btn text color="primary" @click="$refs.menu.save(fecha)"
                      >Aceptar</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="save()">agregar</v-btn>

          <v-btn color="red darken-1" text @click="cerrar()">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment';

export default {
  props: ['habilitar', 'indice', 'saldo'],
  name: 'Desembolsos',
  data: () => ({
    fecha: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    ).toISOString(),
    menu: false,
    monto: 0,
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
  }),
  methods: {
    cerrar() {
      (this.monto = ''), (this.inicioFecha = '');

      this.$emit('update:habilitar', false);
    },
    save() {
      if (this.monto === '') {
        return;
      }
      const numericMonto = parseFloat(this.monto);

      if (numericMonto > this.saldo) {
        this.$swal.fire(
          'Error',
          `<div>
      El monto ingresado ${this.formatter.format(
        numericMonto
      )}  no puede superar el Monto máximo ${this.formatter.format(this.saldo)}.
    </div>`
        );
        return;
      }

      const fechaIngresada = moment(
        this.formatDate.replace(/\//g, '-'),
        'DD/MM/YYYY'
      );

      const fechaHoy = moment(new Date(), 'DD/MM/YYYY').startOf('day');
      if (fechaHoy.isAfter(fechaIngresada)) {
        const startDay = fechaIngresada.format('DD');
        const startMonth = fechaIngresada.format('MM');
        const startYear = fechaIngresada.format('YYYY');
        this.$swal.fire(
          'Error',
          `<div>
      La fecha de ingresada ${startDay}/${startMonth}/${startYear}  no puede ser menor a  ${moment(
        fechaHoy,
        'DD/MM/YYYY'
      ).format('DD/MM/YYYY')}.
    </div>`
        );
        return;
      }

      this.$emit('saveDesembolso', {
        monto: numericMonto,
        fecha: this.formatDate,
        indice: this.indice,
      });

      this.monto = this.formatter.format(0);
      this.fecha = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      );
      this.$emit('update:habilitar', false);
    },
    validateDate(dateString) {
      if (!dateString) {
        return false;
      }

      try {
        const parsedDate = moment(dateString, 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        );

        if (parsedDate.isValid()) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
  },
  watch: {
    fecha(newVal) {
      this.isDateValid = this.validateDate(newVal);
    },
    monto(newVal, oldVal) {
      if (typeof newVal === 'number') {
        newVal = newVal.toString(); // Convierte el número a una cadena
      }
      this.monto = parseFloat(newVal.replace(/[^0-9.,]/g, ''));
    },
  },
  computed: {
    formatDate() {
      return this.fecha ? moment(this.fecha).format('DD-MM-YYYY') : '';
    },
    saldoClass() {
      return this.saldo === 0 ? 'red--text' : '';
    },
  },
  created() {},
};
</script>
<style scoped></style>
