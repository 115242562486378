<template>
  <v-row justify="center">
    <v-dialog v-model="habilitar" persistent max-width="450px">
      <span class="text-h4">Editar Desembolso</span>
      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="text-center">
                <span class="text-h5">Desembolso N°: {{ indice }}</span>
                <v-col cols="12">
                  <span class="font-weight-bold">Saldo Disponible: </span>
                  <span :class="{ 'red--text': saldoEditable === 0 }">
                    {{
                      isNaN(saldoEditable)
                        ? saldoEditable || 0
                        : formatter.format(saldoEditable)
                    }}
                  </span>
                </v-col>
                <v-text-field
                  label="Monto Desembolsado"
                  v-model="data.monto"
                  required
                  :rules="[
                    (v) => (v || '') !== '' || 'El monto es obligatorio',
                  ]"
                  type="number"
                ></v-text-field>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="data.fecha"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaFormat"
                      label="Fecha de inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fecha" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"
                      >Cancelar</v-btn
                    >
                    <v-btn text color="primary" @click="$refs.menu.save(fecha)"
                      >Aceptar</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="modificar()"
            >Modificar</v-btn
          >
          <v-btn color="red darken-1" text @click="cerrar()">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment';

export default {
  props: ['habilitar', 'indice', 'data', 'saldo', 'saldoEditable'],
  name: 'Desembolsos',
  data: () => ({
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
    fecha: '',
    menu: false,
  }),
  methods: {
    cerrar() {
      this.data.monto = '';
      this.fecha = '';
      this.$emit('update:habilitar', false);
    },

    modificar() {
      const numericMonto = parseFloat(this.data.monto);
      if (numericMonto > parseFloat(this.saldoEditable)) {
        this.$swal.fire(
          'Error',
          `<div>
      El monto ingresado ${this.formatter.format(
        numericMonto
      )}  no puede superar el Monto máximo ${this.formatter.format(
        this.saldoEditable
      )}.
    </div>`
        );
        return;
      }

      const data = {
        monto: numericMonto,
        fecha: this.fechaFormat,
        indice: this.indice,
        id: this.data.id,
      };
      this.data.monto = '';
      this.fecha = '';

      this.$emit('editDesembolso', data);

      this.$emit('update:habilitar', false);
    },
  },
  watch: {
    saldoEditable(nuevoValor) {
      return nuevoValor;
    },
  },
  computed: {
    saldoClass() {
      return this.saldoEditable === 0 ? 'red--text' : '';
    },

    fechaFormat() {
      if (this.fecha === '') {
        return moment(this.data.fecha, 'DD-MM-YYYY').format('DD-MM-YYYY');
      }
      return moment(this.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
  created() {},
};
</script>
<style scoped></style>
