<template>
<div>
 <v-timeline class="ml-5">
    <v-timeline-item
      v-for="(n, i) in bitacora"
      :key="i"
      :color="n.color"
    >
      <template slot="opposite" v-if="n.side == 'cliente' && i % 2 !== 0">
        <v-card  class="elevation-2">
        <v-card-title>
          {{formatDate(n.createdAt)}}
        </v-card-title>
        <v-card-text>
          {{n.texto}}
        </v-card-text>
        </v-card>
      </template>
      <template v-if="n.side == 'cliente' && i % 2 === 0">
        <v-card  class="elevation-2">
        <v-card-title class="align-rigth">
          {{formatDate(n.createdAt)}}
        </v-card-title>
        <v-card-text class="text-left">
          {{n.texto}}
        </v-card-text>
        </v-card>
      </template>
      <v-card slot="opposite" v-if="n.side == 'operador' && i % 2 === 0" class="elevation-2">
        <v-card-title>
          {{formatDate(n.createdAt)}}
        </v-card-title>
        <v-card-text class="text-left">
          <v-btn v-if='n.informeURL'
            v-on:click='verDoc(n.informeURL)'
            class="mr-2"
            color='info'> <v-icon>mdi-magnify</v-icon>
          </v-btn>
          {{n.texto}}
        </v-card-text>
      </v-card>
      <v-card v-if="n.side == 'operador' && i % 2 !== 0" class="elevation-2">
        <v-card-title>
          {{formatDate(n.createdAt)}}
        </v-card-title>
        <v-card-text>
          <v-btn v-if='n.informeURL'
            v-on:click='verDoc(n.informeURL)'
            class="mr-2"
            color='info'> <v-icon>mdi-magnify</v-icon>
          </v-btn>
          {{n.texto}}
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
  </div>
</template>
<script>
import moment from 'moment';
import { API_URL } from '@/common/config';

export default {
  props: {
    bitacora: Array,
  },
  methods: {
    verDoc(url) {
      window.open(url);
    },
    formatDate(utc) {
      moment.locale('es');
      return utc
        ? moment(utc).format('L LT')
        : '';
    },
  },
};
</script>
