<template>
  <v-simple-table>
    <tbody>
      <tr v-if="cliente.tipoCliente">
        <td>Cliente</td>
        <td>{{ cliente.nombre }} {{ cliente.apellido }}</td>
      </tr>
      <tr v-if="!cliente.tipoCliente || cliente.tipoCliente === '0'">
        <td>Razon Social</td>
        <td>{{ cliente.razonSocial }}</td>
      </tr>
      <tr v-if="cliente.tipoCliente">
        <td>DNI</td>
        <td>{{ cliente.dni }}</td>
      </tr>
      <tr v-if="!cliente.tipoCliente || cliente.tipoCliente === '0'">
        <td>CUIT</td>
        <td>{{ cliente.cuit }}</td>
      </tr>
      <tr>
        <td>Correo Electrónico</td>
        <td>{{ cliente.domicilioElectronico || '-' }}</td>
      </tr>
      <tr>
        <td>Domicilio Real</td>
        <td>{{ cliente.domicilioReal || '-' }}</td>
      </tr>
      <tr>
        <td>Domicilio Legal</td>
        <td>{{ cliente.domicilioLegal || '-' }}</td>
      </tr>
      <tr>
        <td>Número Teléfono</td>
        <td>{{ cliente.telefonoFijo || '-' }}</td>
      </tr>
      <tr>
        <td>Fecha de Otorgamiento</td>
        <td></td>
      </tr>
      <tr>
        <td>Unidad de Gestión</td>
        <td>{{ operatoria.fideicomiso_id.nombre }}</td>
      </tr>
      <tr>
        <td>Monto</td>
        <td></td>
      </tr>
      <tr>
        <td>Cantidad de Cuotas</td>
        <td></td>
      </tr>
      <tr>
        <td>Periodicidad de Cuotas</td>
        <td></td>
      </tr>
      <tr>
        <td>Cuotas Impagas</td>
        <td></td>
      </tr>
      <tr>
        <td>Gastos</td>
        <td></td>
      </tr>
      <tr>
        <td>Garantía</td>
        <td></td>
      </tr>
      <tr>
        <td>CodCliente</td>
        <td v-if="codcliente">{{ codcliente }}</td>
        <td>
          <v-btn :loading="loading" color="primary" @click="sincronizar()"
            >Sincronizar</v-btn
          >
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';
import { getCodJerarquia } from '@/common/util';

export default {
  data: () => ({
    loading: false,
    codclienteLocal: null,
  }),
  name: 'Datos',
  props: ['cliente', 'operatoria', 'codcliente', 'legajo'],
  methods: {
    async sincronizar() {
      this.loading = true;
      const codjerarquia = await getCodJerarquia(this.operatoria.nombre);

      const url = `${API_URL}api/clientes/financiamiento/sincOne?legajo=${this.legajo}&codjerarquia=${codjerarquia}`;

      if (!codjerarquia) {
        await this.$swal.fire({
          icon: 'Error',
          title: 'No fue posible la sincronización',
          showConfirmButton: false,
          timer: 2000,
        });
      }
      await axios
        .get(url)
        .then((r) => {
          if (r.data.status === 'success') {
            this.$swal.fire(
              'Trilay',
              `El cliente se sincronizo con codcliente ${r.data.codcliente}, codjerarquia ${codjerarquia}`,
              'success'
            );
            this.codcliente = r.data.codcliente;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$swal.fire('Trilay', err, 'error');
          this.loading = false;
        });
    },
  },
};
</script>
