<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        persistent
        max-width="450px"
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            block
            class="ml-5"
            color="warning"
            v-bind="attrs"
            v-on="on"
            >
            {{ name }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
            <span class="text-h5">{{ title }}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
              <v-form id="myform"
              ref="form"
              lazy-validation
            >
             <v-row>
                <v-col
                    cols="12"
                >
                    <v-text-field
                    label="Monto"
                    v-model="monto"
                    :rules="montoRules"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                    label="Plazo"
                    v-model="plazo"
                    :rules="plazoRules"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                    label="Periodo de gracia"
                    v-model="periodo_gracia"
                    :rules="periodoRules"
                    required
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                    label="Tasa"
                    v-model="tasa"
                    disabled
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                    label='Periocidad de pagos'
                    v-model='periodicidadPagos'
                    :rules="[v => !!v || 'La periodicidad de pagos es obligatoria']"
                    :items="[{value: 1, text: 'Mensual'},{value: 2, text: 'Bimestral'},
                    {value: 3, text: 'Trimestral'},
                    {value: 6, text: 'Semestral'}, {value: 12, text: 'Anual'}]"
                    >
                    </v-select>
                </v-col>
                <v-col
                    cols='12'
                >
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="fecha"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="formatDate"
                            label="Fecha Inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="fecha"
                        no-title
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                            Salir
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(fecha)"
                        >
                            Aceptar
                        </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="getRefinanc()"
            >
                Refinanciar
            </v-btn>
            <v-btn
                color="red darken-1"
                text
                @click="dialog = false"
            >
                Salir
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { API_URL, SIMULADOR_URL } from '@/common/config';
import moment from 'moment';
import axios from 'axios';

export default {
  data: () => ({
    dialog: false,
    title: 'Refinanciar',
    name: 'Refinanciar',
    menu: false,
    monto: '',
    desdeCuota: '',
    hastaCuota: '',
    cuadroMarcha: '',
    periodicidadPagos: '',
    periodo_gracia: '',
    tasa: '',
    plazo: '',
    lineaCredito: '',
    token: localStorage.getItem('token'),
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
      .toISOString().substr(0, 10),
  }),
  props: {
    operatoria_id: Number,
    datosParametros: Array,
    servParametros: Array,
  },
  methods: {
    async getRefinanc() {
      const url = `${SIMULADOR_URL}${this.monto}/${this.plazo}/${this.periodicidadPagos}/${this.tasa}/${this.formatDate}/${this.periodo_gracia}`;
      if (this.$refs.form.validate()) {
        await axios
          .get(url)
          .then((response) => {
            this.guardarCuadro(response.data);
            this.guardarClienteParametro(this.$route.params.id);
            this.$emit('generarRefinanc', Object.entries(response.data));
            this.$swal('Refinanciado', 'Se ha realizado la operación correctamente', 'success');
            this.dialog = false;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async getValor(claveParametro, lineaCredito) {
      const data = {
        lineaCredito_id: lineaCredito,
        clave: claveParametro,
      };
      const url = `${API_URL}api/servicioParametros/byclave`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .post(url, data, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.tasa = response.data.valor;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async guardarClienteParametro(lineaccId) {
      this.datosParametros.find((p) => p.name === 'plazo_credito').value = this.plazo;
      this.datosParametros.find((p) => p.name === 'monto').value = this.monto;
      this.datosParametros.find((p) => p.name === 'periodo_gracia').value = this.periodo_gracia;
      this.datosParametros.find((p) => p.name === 'periodicidad_pagos').value = this.periodicidadPagos;
      this.datosParametros.find((p) => p.name === 'fecha_inicio').value = this.formatDate;
      await axios
        .post(`${API_URL}api/clienteParametros/nuevo`, {
          token: this.token,
          lineaCredito_id: lineaccId,
          datos: this.datosParametros,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            console.log(response);
          } else {
            console.error('No se actualizaron los parametros del Cliente');
          }
        });
    },
    guardarCuadro(datos) {
      const data = {
        lineaCreditoCliente_id: this.$route.params.id,
        activo: true,
        datos,
        origen: 'refinanciar',
      };
      const url = `${API_URL}api/historicoCuadroMarcha/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          console.log('ok');
        } else if (response.data.status === 'error') {
          console.log('no ok');
        }
      });
    },
  },
  computed: {
    formatDate() {
      return this.fecha
        ? moment(this.fecha).format('DD-MM-YYYY')
        : '';
    },
    periodoRules() {
      return [
        (v) => !!v || 'Falta el periodo',
        (v) => (v >= 0 && v <= parseInt(this.servParametros.find((e) => e.clave === 'periodo_gracia_maximo').valor, 10)) || `El período debe gracia máximo ${this.servParametros.find((e) => e.clave === 'periodo_gracia_maximo').valor}`,
      ];
    },
    montoRules() {
      return [
        (v) => !!v || 'Falta monto de la operación',
        (v) => (v >= parseInt(this.servParametros.find((e) => e.clave === 'monto_minimo').valor, 10) && v <= parseInt(this.servParametros.find((e) => e.clave === 'monto_maximo').valor, 10)) || `El monto debe estar entre ${this.servParametros.find((e) => e.clave === 'monto_minimo').valor} y ${this.servParametros.find((e) => e.clave === 'monto_maximo').valor}`,
      ];
    },
    plazoRules() {
      return [
        (v) => !!v || 'Falta valor plazo',
        (v) => (v >= parseInt(this.servParametros.find((e) => e.clave === 'plazo_credito_minimo').valor, 10) && v <= parseInt(this.servParametros.find((e) => e.clave === 'plazo_credito_maximo').valor, 10)) || `El plazo debe estar entre ${this.servParametros.find((e) => e.clave === 'plazo_credito_minimo').valor} y ${this.servParametros.find((e) => e.clave === 'plazo_credito_maximo').valor}`,
      ];
    },
  },
  created() {
    this.getValor('tasa', this.operatoria_id);
  },
};
</script>
