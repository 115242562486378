<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="450px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block class="ml-5" color="primary" v-bind="attrs" v-on="on">
          {{ name }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Desde Cuota"
                  v-model="desdeCuota"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Hasta Cuota"
                  v-model="hastaCuota"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Plazo"
                  v-model="plazo"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  label="Periocidad de pagos"
                  v-model="periodicidadPagos"
                  :rules="[
                    (v) => !!v || 'La periodicidad de pagos es obligatoria',
                  ]"
                  :items="[
                    { value: 1, text: 'Mensual' },
                    { value: 2, text: 'Bimestral' },
                    { value: 3, text: 'Trimestral' },
                    { value: 6, text: 'Semestral' },
                    { value: 12, text: 'Anual' },
                  ]"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="fecha"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      label="Fecha Inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fecha" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Salir
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(fecha)">
                      Prorrogar
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="getProrroga()">
            Prorroga
          </v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { API_URL, SIMULADOR_URL } from '@/common/config';
import moment from 'moment';
import axios from 'axios';

export default {
  data: () => ({
    dialog: false,
    title: 'Prorroga',
    name: 'Prorroga',
    menu: false,
    desdeCuota: '',
    hastaCuota: '',
    cuadroMarcha: '',
    tasa: '',
    plazo: '',
    lineaCredito: '',
    fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  props: {
    periodicidadPagos: Number,
    operatoria_id: Number,
  },
  methods: {
    async getProrroga() {
      console.log('Empieza prorroga');
      const cuadroMarcha = Object.entries(this.cuadroMarcha);
      const cuotaInicial = cuadroMarcha.filter(
        (el) => el[1].N_Cuota === parseInt(this.desdeCuota, 10)
      );
      const cuotaFinal = cuadroMarcha.filter(
        (el) => el[1].N_Cuota === parseInt(this.hastaCuota, 10)
      );
      const monto =
        cuotaInicial[0][1].Saldo_Inicial - cuotaFinal[0][1].Saldo_Final;
      const url = `${SIMULADOR_URL}${monto}/${this.plazo}/${this.periodicidadPagos}/${this.tasa}/${this.formatDate}/0`;
      await axios
        .get(url)
        .then((response) => {
          console.log('Prorroga: ', response.data);
          const dataProrroga = {
            cm_inicial: this.cuadroMarcha,
            cm_prorrogas: response.data,
          };
          axios.post(`${SIMULADOR_URL}prorroga`, dataProrroga).then((r) => {
            console.log('Cuadro Marcha: ', this.cuadroMarcha);
            console.log('Nuevo Cuadro: ', r.data);
            this.guardarCuadro(r.data);
            this.$emit('generarProrroga', Object.entries(r.data));
          });
        })
        .catch((error) => {
          console.error(error);
          // this.loadingCalcular = false;
        });
      this.dialog = false;
    },
    async getCuadroMarcha() {
      const lineaCreditoClienteId = this.$route.params.id;
      const url = `${API_URL}api/historicoCuadroMarcha/${lineaCreditoClienteId}`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .get(url, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.cuadroMarcha = response.data.historicoCuadro;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getValor(claveParametro, lineaCredito) {
      const data = {
        lineaCredito_id: lineaCredito,
        clave: claveParametro,
      };
      const url = `${API_URL}api/servicioParametros/byclave`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios
        .post(url, data, header)
        .then((response) => {
          if (response.data.status === 'success') {
            this.tasa = response.data.valor;
            console.log('tasa: ', response.data.valor);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    guardarCuadro(datos) {
      const data = {
        lineaCreditoCliente_id: this.$route.params.id,
        activo: true,
        datos,
        origen: 'prorroga',
      };
      const url = `${API_URL}api/historicoCuadroMarcha/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          console.log('ok');
        } else if (response.data.status === 'error') {
          console.log('no ok');
        }
      });
    },
  },
  computed: {
    formatDate() {
      return this.fecha ? moment(this.fecha).format('DD-MM-YYYY') : '';
    },
  },
  created() {
    this.getCuadroMarcha();
    this.getValor('tasa', this.operatoria_id);
  },
};
</script>
