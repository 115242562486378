<template>
  <div v-show="montoAprobado !== null">
    <table border="2">
      <thead>
        <tr>
          <th colspan="2">Desembolso</th>
          <th colspan="2">Fecha carga</th>
          <th>Monto</th>
          <th>Total Capital otorgado</th>
          <th>Actions</th>
          <th colspan="2">Documento I</th>
          <th colspan="2">Documento II</th>
          <th colspan="2">Fecha Desembolso</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(desembolsos, index) in desembolsosCredito"
          :key="index"
          :class="{
            'disabled-row': desembolsos.aprobado === true || !botonADesembolso,
          }"
        >
          <td colspan="2">{{ desembolsos.numeroDesembolso }}</td>
          <td colspan="2">{{ formatDate(desembolsos.fecha, 'DD-MM-YYYY') }}</td>
          <td>{{ formatter.format(desembolsos.monto) }}</td>
          <td>{{ formatter.format(desembolsos.saldoCapital) }}</td>
          <td>
            <v-btn
              small
              fab
              dark
              color="warning"
              @click="
                editarDesembolso(
                  desembolsos.numeroDesembolso,
                  desembolsos.id,
                  desembolsos.monto,
                  desembolsos.fecha
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              fab
              dark
              color="red"
              @click="deleteDesembolso(desembolsos.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <!-- <v-btn
              small
              fab
              dark
              color="green"
              :disabled="desembolsos.docI === '' && desembolsos.docII === ''"
              @click="enviarDesembolso(desembolsos.id)"
            >
              <v-icon>mdi-checkbook-arrow-left</v-icon>
            </v-btn> -->
          </td>
          <td colspan="2">
            <div class="file-input-container">
              <div v-if="!desembolsos.docI">
                <v-file-input
                  :disabled="desembolsos.docI !== ''"
                  v-model="desembolsos.archivo"
                  class="mt-5"
                  label="Subir archivo"
                  outlined
                  dense
                  show-size
                ></v-file-input>
              </div>
              <span style="color: blue" v-else>
                {{ desembolsos.nameDocI }}</span
              >
              <v-btn
                :disabled="desembolsos.docI !== ''"
                v-on:click="
                  subirDoc(
                    desembolsos.archivo,
                    desembolsos.id,
                    desembolsos.numeroDesembolso,
                    1
                  )
                "
                color="primary"
                small
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-btn
                color="success"
                :disabled="desembolsos.docI === ''"
                v-on:click="verDoc(desembolsos.docI)"
                small
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                color="error"
                :disabled="desembolsos.docI === ''"
                v-on:click="eliminarDoc(desembolsos.id, 1)"
                small
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </div>
          </td>
          <td colspan="2">
            <div class="file-input-container">
              <div v-if="!desembolsos.docII">
                <v-file-input
                  :disabled="desembolsos.docII !== ''"
                  v-model="desembolsos.archivoII"
                  class="mt-5"
                  label="Subir archivo"
                  outlined
                  dense
                  show-size
                ></v-file-input>
              </div>
              <span style="color: blue" v-else>
                {{ desembolsos.nameDocII }}</span
              >
              <v-btn
                :disabled="desembolsos.docII !== ''"
                v-on:click="
                  subirDoc(
                    desembolsos.archivoII,
                    desembolsos.id,
                    desembolsos.numeroDesembolso,
                    2
                  )
                "
                color="primary"
                small
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
              <v-btn
                color="success"
                :disabled="desembolsos.docII === ''"
                v-on:click="verDoc(desembolsos.docII)"
                small
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                color="error"
                :disabled="desembolsos.docII === ''"
                v-on:click="eliminarDoc(desembolsos.id, 2)"
                small
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </div>
          </td>
          <td>{{ desembolsos.fecha_desembolso }}</td>
        </tr>
      </tbody>
    </table>

    <v-btn
      class="mt-5"
      @click="modal = true"
      block
      color="primary"
      v-if="desembolsos > desembolsosCredito.length"
      :disabled="botonADesembolso"
    >
      Agregar Desembolso
    </v-btn>
    <br />

    <ModalDesembolso
      :habilitar="modal"
      :indice="newIndice"
      :saldo="saldo"
      @saveDesembolso="addDesembolso"
      @update:habilitar="modal = $event"
    ></ModalDesembolso>
    <ModalEditarDesembolso
      :habilitar="modalEdit"
      :indice="indice"
      :data="data"
      :saldo="saldo"
      :saldoEditable="saldoEditable"
      @editDesembolso="editDesembolso"
      @update:habilitar="modalEdit = $event"
    ></ModalEditarDesembolso>
  </div>
</template>
<script>
import moment from 'moment';
import ModalDesembolso from '../ModalDesembolso.vue';
import ModalEditarDesembolso from '../ModalEditarDesembolso.vue';
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  name: 'Desembolsos',
  components: { ModalDesembolso, ModalEditarDesembolso },
  props: ['legajo', 'desembolsos'],
  data: () => ({
    montoAprobado: null,
    desembolsosCredito: [],
    botonADesembolso: true,
    saldo: '',
    saldoEditable: '',
    data: {
      legajo: 0,
      monto: '',
      fecha: '',
      desembolso: 0,
      id: 0,
    },
    edit: false,
    indice: 0,
    newIndice: 0,
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
    modal: false,
    modalEdit: false,
    token: localStorage.getItem('token'),
    mensaje: '',
    alertError: false,
  }),

  methods: {
    async enviarDesembolso(id) {
      this.$swal({
        title: '¿desea confirmar?',
        text: 'Desea enviar el desembolso para su aprobación',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, Cancelar',
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.mostrarLoading = true;
          this.alertError = false;
          const url = `${API_URL}api/desembolsos/${id}`;
          const header = {
            'Content-Type': 'application/json',
            token: this.token,
          };

          axios
            .put(url, header)
            .then(async (response) => {
              if (response.data.status === 'success') {
                await this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Se envio desembolso ',
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.listDesembolsos();
              } else {
                this.alertError = true;
                this.mensaje = response.data.message;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    formatDate(
      dateString,
      inputFormat = 'DD-MM-YYYY',
      outputFormat = 'DD-MM-YYYY'
    ) {
      return moment(dateString, inputFormat).format(outputFormat);
    },
    async editDesembolso(datos) {
      this.mostrarLoading = true;

      if (
        datos.monto === 0 ||
        isNaN(datos.monto) ||
        datos.fecha === '' ||
        datos.indice === 0 ||
        datos.id <= 0
      ) {
        this.mostrarLoading = false;
        this.alertError = true;
        await this.$swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Complete todos los campos',
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }

      const data = {
        legajo: this.legajo,
        monto: datos.monto,
        fecha: datos.fecha,
        desembolso: datos.indice,
        id: datos.id,
      };

      const url = `${API_URL}api/desembolsos`;
      const header = {
        headers: {
          token: this.token,
        },
      };

      axios
        .put(url, data, header)
        .then(async (response) => {
          if (response.data.status === 'success') {
            await this.$swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Desembolso Creado',
              showConfirmButton: false,
              timer: 1500,
            });
            (this.data = {
              legajo: 0,
              monto: '',
              fecha: '',
              desembolso: 0,
              id: 0,
            }),
              this.$router.go(0);
            this.listDesembolsos();
          } else {
            this.alertError = true;
            this.mensaje = response.data.message;
            await this.$swal.fire({
              position: 'center',
              icon: 'success',
              title: this.mensaje,
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editarDesembolso(indice, id, monto, date) {
      const fecha = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
      this.indice = indice;
      this.data = { monto, fecha, id };
      this.modalEdit = true;
    },
    subirDoc(archivo, id, desembolso, doc) {
      if (!archivo || archivo.length <= 0) {
        return;
      }
      this.mostrarLoading = true;
      this.alertError = false;
      if (archivo.size > 10 * 1024 * 1024) {
        console.log('El documento excede el tamaño máximo de 10 MB.');
        return;
      }
      const url = `${API_URL}api/documentaciondesembolso/`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: this.token,
        },
      };
      const formData = new FormData();
      formData.append('id', id);
      formData.append('legajo', this.legajo);
      formData.append('desembolsoNro', desembolso);
      formData.append('doc', doc);
      formData.append('adjunto', archivo);
      axios
        .post(url, formData, header)
        .then(async (response) => {
          if (response.data.status === 'success') {
            this.listDesembolsos();
            await this.$swal.fire({
              icon: 'success',
              title: 'Documento almacenado',
              showConfirmButton: false,
              timer: 1500,
            });
            this.$emit('listar-liquidaciones');
          } else {
            this.alertError = true;
            this.mensaje = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    eliminarDoc(documentoId, doc) {
      this.mostrarLoading = true;
      this.alertError = false;
      const url = `${API_URL}api/documentaciondesembolso/${documentoId}/${doc}`;
      const header = {
        headers: {
          token: this.token,
        },
      };

      axios
        .delete(url, header)
        .then(async (response) => {
          if (response.data.status === 'success') {
            await this.$swal.fire({
              icon: 'success',
              title: 'Documento eliminado',
              showConfirmButton: false,
              timer: 1500,
            });
            this.$emit('listar-liquidaciones');
            this.listDesembolsos();
          } else {
            this.alertError = true;
            this.mensaje = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verDoc(url) {
      window.open(url);
    },
    async mostrarError() {
      this.alertError = false;
      await this.$swal.fire({
        icon: 'error',
        title: this.mensaje,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    async deleteDesembolso(documentoId) {
      this.$swal({
        title: '¿Esta seguro?',
        text: 'Esta acción elimina el desembolso',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, Cancelar',
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.mostrarLoading = true;
          this.alertError = false;
          const url = `${API_URL}api/desembolsos/${documentoId}`;
          const header = {
            headers: {
              token: this.token,
            },
          };

          axios
            .delete(url, header)
            .then(async (response) => {
              if (response.data.status === 'success') {
                await this.$swal.fire({
                  icon: 'info',
                  title: 'Eliminar Desembolso?',
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.listDesembolsos();
              } else {
                this.alertError = true;
                this.mensaje = response.data.message;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    calculateSaldoCapital() {
      let saldoCapital = 0;
      this.desembolsosCredito.forEach((disbursement) => {
        disbursement.saldoCapital = saldoCapital += disbursement.monto;
      });

      if (this.desembolsosCredito.length > 1) {
        this.saldoEditable =
          parseFloat(this.montoAprobado) -
          this.desembolsosCredito[this.desembolsosCredito.length - 2]
            .saldoCapital;
      } else {
        this.saldoEditable = parseFloat(this.montoAprobado);
      }

      this.saldo = parseFloat(this.montoAprobado) - saldoCapital;
    },
    async addDesembolso(datos) {
      this.mostrarLoading = true;

      if (
        datos.monto === '0' ||
        isNaN(datos.monto) ||
        datos.fecha === '' ||
        datos.indice === 0
      ) {
        this.mostrarLoading = false;
        this.alertError = true;
        await this.$swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Complete todos los campos',
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      const data = {
        legajo: this.legajo,
        monto: datos.monto,
        fecha: datos.fecha,
        desembolso: datos.indice,
      };
      const url = `${API_URL}api/desembolsos`;
      const header = {
        headers: {
          token: this.token,
        },
      };

      axios
        .post(url, data, header)
        .then(async (response) => {
          if (response.data.status === 'success') {
            await this.$swal.fire({
              icon: 'success',
              title: 'Desembolso Creado',
              showConfirmButton: false,
              timer: 1500,
            });
            this.listDesembolsos();
            this.$router.go(0);
          } else {
            this.alertError = true;
            this.mensaje = response.data.message;
            await this.$swal.fire({
              icon: 'error',
              title: this.mensaje,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetNewDesembolso() {
      this.newDesembolso = { id: '', Fecha: '', Monto: '' };
    },
    btnAgregarDesembolso() {
      if (this.desembolsosCredito.length === 0) {
        this.botonADesembolso = false;
        return;
      }
      this.botonADesembolso = this.desembolsosCredito[
        this.desembolsosCredito.length - 1
      ]
        ? this.desembolsosCredito[this.desembolsosCredito.length - 1]
            .fecha_desembolso === ''
        : true;
    },
    async listDesembolsos() {
      try {
        const header = {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: localStorage.getItem('token'),
          },
        };
        const url = `${API_URL}api/desembolsos/${this.legajo}`;
        const { data } = await axios.get(url, header);

        if (data.status === 'Error') {
          await this.$swal.fire({
            icon: 'error',
            title: 'Error al cargar los desembolsos',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (data.status === 'success') {
          this.desembolsosCredito = data.listDesembolsos;
          this.calculateSaldoCapital();
          this.desembolsosCredito = this.desembolsosCredito.map((ele) => {
            return { ...ele, archivo: [], archivoII: [] };
          });

          if (this.desembolsosCredito.length > 0) {
            const numero = parseInt(this.desembolsosCredito.length);
            this.newIndice = this.desembolsosCredito[numero - 1]
              .numeroDesembolso
              ? this.desembolsosCredito[numero - 1].numeroDesembolso + 1
              : 1;
          }

          if (this.desembolsosCredito.length === 0) {
            this.newIndice = 1;
          }

          this.btnAgregarDesembolso();
        }
      } catch (error) {
        console.error(error);
      }
    },
    getClienteParametros() {
      const url = `${API_URL}api/clienteParametros`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      const data = {
        lineaCreditoCliente_id: this.legajo,
      };
      axios.post(url, data, header).then((response) => {
        this.clienteParametro = response.data.ClienteParametro;

        const monto = this.clienteParametro.find(
          (el) => el.tipo_parametro_id.clave === 'monto_aprobado'
        );
        this.montoAprobado = monto && monto.valor ? monto.valor : null;

        if (response.data.status === 'error') {
          this.$swal.fire(
            'Ocurrió un error',
            'No se pudieron obtener los parametros ingresados por el cliente',
            'warning'
          );
        }
      });
    },
  },
  created() {
    this.getClienteParametros();
    this.listDesembolsos();
    this.btnAgregarDesembolso();
  },
  watch: {
    montoAprobado(nuevoValor, viejoValor) {
      if (nuevoValor !== viejoValor) {
        this.getClienteParametros();
      }
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}
.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}
</style>
