<template>
  <div>
    <v-card-text v-show="ocultarMonto">
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <v-text-field
            label="Monto aprobado"
            required
            :rules="[(v) => (v || '') !== '' || 'El monto es obligatorio']"
            v-model="montoFormateado"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-btn @click="ingresarMonto" class="info" block>
            Ingresar monto aprobado
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <div v-for="desembolso in desembolsosCredito" :key="desembolso.id">
      <v-simple-table
        class="my-table"
        :class="{ 'disabled-table': desembolso.aprobado }"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>Número Desembolso: {{ desembolso.numeroDesembolso }}</th>
              <th>Neto</th>
              <th>IVA</th>
              <th>IIBB</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="desembolso.numeroDesembolso === 1">
              <td>Honorarios MFSA</td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.honorariosMfs.toFixed(2)
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.honorariosMfsIva.toFixed(2)
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.iibbHonorariosMfs.toFixed(2)
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.totalHonorariosMfs.toFixed(2)
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Honorarios escribano</td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.otrosGastos.toFixed(2)
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.ivaOtrosGastos.toFixed(2)
                  )
                }}
              </td>
              <td>
                {{ formatCurrency(iibbCertificacionesNotariales.toFixed(2)) }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.totalOtrosGastos.toFixed(2)
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Ingresos Brutos</td>
              <td>
                {{ formatCurrency(certificacionesNotariales.toFixed(2)) }}
              </td>
              <td>
                {{ formatCurrency(certificacionesNotarialesIva.toFixed(2)) }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.iibbOtrosGastos.toFixed(2)
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    desembolso.listTotGastos.iibbOtrosGastos.toFixed(2)
                  )
                }}
              </td>
            </tr>
            <tr>
              <td><b>Totales</b></td>
              <td>
                <b>
                  {{
                    formatCurrency(
                      desembolso.listTotGastos.totalNeto.toFixed(2)
                    )
                  }}</b
                >
              </td>
              <td>
                <b>
                  {{
                    formatCurrency(desembolso.listTotGastos.totalIva.toFixed(2))
                  }}</b
                >
              </td>
              <td>
                <b>
                  {{
                    formatCurrency(
                      desembolso.listTotGastos.totalIibb.toFixed(2)
                    )
                  }}</b
                >
              </td>
              <td>
                <b>
                  {{
                    formatCurrency(
                      desembolso.listTotGastos.totalGeneral.toFixed(2)
                    )
                  }}</b
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="centered-table">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Monto Aprobado</th>
                <th class="table-header text-left">Monto Disponible</th>
                <th class="table-header text-left">Monto Desembolso</th>
                <th class="table-header text-left">Total Gastos</th>
                <th class="table-header text-left">Monto a desembolsar</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{
                    formatCurrency(parseFloat(montoAprobado).toFixed(2) || 0)
                  }}
                </td>
                <td>
                  {{
                    formatCurrency(
                      (
                        parseFloat(montoAprobado) - parseFloat(montoDisponible)
                      ).toFixed(2) || 0
                    )
                  }}
                </td>
                <td>
                  {{ formatCurrency(desembolso.monto) }}
                </td>

                <td class="table-cell">
                  {{ formatCurrency(desembolso.totalGastos.toFixed(2) || 0) }}
                </td>
                <td class="table-cell">
                  {{
                    formatCurrency(desembolso.montoDesembolsar.toFixed(2) || 0)
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <v-alert dense border="left" type="warning" v-if="documentacionOk">
      Necesita cargar la dosumentacion de respaldo para este ultimo desembolso
    </v-alert>
    <div v-show="!ocultarMonto && ingresarMontoAprbado && !documentacionOk">
      <ModalGastos
        :lineacreditocliente_id="legajo"
        :idDesembolso="idDesembolso"
        @creditoLiquidado="listDesembolsos()"
      ></ModalGastos>
      <v-btn @click="liquidar" class="info" block> Liquidar </v-btn>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import ModalGastos from '@/components/Gastos/ModalGastos.vue';
import { API_URL } from '@/common/config';

export default {
  components: {
    ModalGastos,
  },
  name: 'LiquidacionFinanciamiento',
  props: ['legajo', 'reRender'],
  data: () => ({
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
    honorarios: false,
    dataTable: { efectivo: 0 },
    ocultarMonto: true,
    ingresarMontoAprbado: false,
    montoAprobado: 0,
    monto: null,
    totalAprobado: 0,
    montoFormateado: null,
    montoSolicitado: 0,
    montoDisponible: 0,
    idDesembolso: 0,
    desembolsosCredito: [],
    liquidaciones: [],
    existeAnticipo: false,
    gastos: [],
    documentacionOk: false,
    certificacionesNotariales: 0,
    certificacionesNotarialesIva: 0,
    iibbCertificacionesNotariales: 0,
  }),

  methods: {
    async ingresarMonto() {
      const numericMonto = parseFloat(this.montoFormateado);
      const montoMaximo = parseFloat(this.montoSolicitado);
      if (numericMonto > montoMaximo) {
        this.$swal.fire(
          'Error',
          `<div>
      El monto ingresado ${this.formatter.format(
        numericMonto
      )}  no puede superar el máximo ${this.formatter.format(montoMaximo)}.
    </div>`
        );
        return;
      }
      const query = {
        method: 'post',
        url: `${API_URL}api/clienteParametros/monto/`,
        headers: {
          token: localStorage.getItem('token'),
        },
        data: {
          lineaCredito_id: this.legajo,
          datos: [
            {
              id: 130,
              nombre: 'Monto Aprobado',
              value: this.montoFormateado,
            },
          ],
        },
      };
      const {
        data: { status, gastos },
      } = await axios(query);

      if (status === 'success') {
        this.montoAprobado = this.montoFormateado;
        this.montoFormateado = '';
        this.ocultarMonto = false;
        this.$emit('monto-cargado');
        await this.$swal.fire({
          icon: 'success',
          title: `Monto Registrado`,
          showConfirmButton: false,
          timer: 2000,
        });
        //this.$router.go(0);
        this.$router.push(`/gestionar/${this.legajo}#desembolsos`);
      } else {
        await this.$swal.fire({
          icon: 'error',
          title: `No se pudo registrar el monto`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    formatCurrency(value) {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) {
        return value;
      }
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
      });

      return formatter.format(numericValue);
    },
    calcularTotales(gastos) {
      let honorariosMfs = 0;
      let iibbHonorariosMfs = 0;
      let honorariosMfsIva = 0;
      let totalHonorariosMfs = 0;
      let otrosGastos = 0;
      let ivaOtrosGastos = 0;
      let iibbOtrosGastos = 0;
      let totalOtrosGastos = 0;
      let totalGeneral = 0;
      let totalIibb = 0;
      let totalIva = 0;
      let totalNeto = 0;
      gastos.forEach((g) => {
        honorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? honorariosMfs + parseFloat(g.administrativos.administrativo)
            : honorariosMfs;

        iibbHonorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? iibbHonorariosMfs + parseFloat(g.iibb)
            : iibbHonorariosMfs;

        honorariosMfsIva =
          g.descripcion === 'Gastos Administrativos'
            ? honorariosMfsIva + parseFloat(g.administrativos.iva)
            : honorariosMfsIva;

        totalHonorariosMfs =
          honorariosMfs + honorariosMfsIva + iibbHonorariosMfs;
        //--------
        otrosGastos =
          g.descripcion === 'Otorgamiento'
            ? otrosGastos + parseFloat(g.administrativos.otorgamiento)
            : otrosGastos;

        ivaOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? ivaOtrosGastos + parseFloat(g.administrativos.iva)
            : ivaOtrosGastos;

        iibbOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? iibbOtrosGastos + parseFloat(g.iibb)
            : iibbOtrosGastos;

        totalOtrosGastos = otrosGastos + ivaOtrosGastos;

        totalGeneral = totalHonorariosMfs + totalOtrosGastos + iibbOtrosGastos;
        totalIibb = iibbHonorariosMfs + iibbOtrosGastos;
        totalIva = honorariosMfsIva + ivaOtrosGastos;
        totalNeto = otrosGastos + honorariosMfs;
      });
      return {
        honorariosMfs,
        iibbHonorariosMfs,
        honorariosMfsIva,
        totalHonorariosMfs,
        otrosGastos,
        ivaOtrosGastos,
        iibbOtrosGastos,
        totalOtrosGastos,
        totalGeneral,
        totalIibb,
        totalIva,
        totalNeto,
      };
    },
    async getLiquidaciones(idDesembolso) {
      // this.loadingLiquidar = true;
      try {
        const query = {
          method: 'GET',
          url: `${API_URL}api/desembolso/liquidacionesanr/${this.legajo}/${idDesembolso}`,
          headers: {
            'Content-Type': 'multipart/form-data',
            token: localStorage.getItem('token'),
          },
        };

        const response = await axios(query);
        const liquidaciones = response.data.liquidaciones;
        if (liquidaciones.length > 0) {
          return liquidaciones;
        }
        return [];
      } catch (error) {
        console.error('Error fetching liquidaciones:', error);
        // Handle error appropriately
      }
    },
    async getGastos(idDesembolso) {
      const query = {
        method: 'post',
        url: `${API_URL}api/gastosanr/cc`,
        headers: {
          token: localStorage.getItem('token'),
        },
        data: {
          lineacreditocliente_id: this.legajo,
          idDesembolso: idDesembolso,
        },
      };
      const {
        data: { status, gastos },
      } = await axios(query);

      if (status === 'success') {
        return gastos;
      }
      return [];
    },
    async getMonto() {
      try {
        const url = `${API_URL}api/clienteParametros`;
        const headers = {
          token: localStorage.getItem('token'),
        };
        const data = {
          lineaCreditoCliente_id: this.legajo,
        };

        const response = await axios.post(url, data, { headers });

        const parametro = response.data.ClienteParametro.find(
          (parametro) =>
            parametro.tipo_parametro_id.clave === 'monto_total_solicitado'
        );

        const existMonto = response.data.ClienteParametro.find(
          (parametro) => parametro.tipo_parametro_id.clave === 'monto_aprobado'
        );
        if (existMonto) {
          this.ocultarMonto = false;
          this.montoAprobado = existMonto.valor;
        } else {
          this.ocultarMonto = true;
        }

        if (!parametro) {
          this.$swal.fire(
            'Error',
            'No se encontró el parámetro solicitado',
            'warning'
          );
          return; // Detener la ejecución si no se encuentra el parámetro
        }

        this.montoSolicitado = parametro.valor;
      } catch (error) {
        console.error('Error al obtener el monto solicitado:', error);
        this.$swal.fire(
          'Error',
          'Ocurrió un error al obtener los datos del cliente. Por favor, inténtalo nuevamente.',
          'error'
        );
      }
    },
    async fetchData() {
      await this.getMonto();
      await this.listDesembolsos();
    },
    async liquidar() {
      if (this.monto === 0) {
        await this.$swal.fire({
          icon: 'error',
          title: `Monto no puede ser 0`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      const query = {
        method: 'post',
        url: `${API_URL}api/solicitud/liquidacionSubtipo`,

        data: {
          lineaCreditoCliente_id: this.legajo,
          concepto: this.idDesembolso,
          totalAprobado: this.totalAprobado,
          neto: this.monto,
          servicio: 'ANR',
          honorarios: this.honorarios,
        },
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const {
        data: { status, message },
      } = await axios(query);
      if (status === 'success') {
        const url = `${API_URL}api/desembolsos/${this.idDesembolso}`;
        const header = {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        };

        axios
          .put(url, header)
          .then(async (response) => {
            if (response.data.status === 'success') {
              console.log('desembolso aceptado');
            }
          })
          .catch((error) => {
            console.log(error);
          });
        await this.$swal.fire({
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.getLiquidaciones();
        this.listDesembolsos();
        this.$router.go(0);
      } else {
        await this.$swal.fire({
          icon: 'error',
          title: 'Algo salio mal!!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async listDesembolsos() {
      try {
        const header = {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: localStorage.getItem('token'),
          },
        };
        const url = `${API_URL}api/desembolsos/${this.legajo}`;
        const { data } = await axios.get(url, header);

        if (data.status === 'Error') {
          await this.$swal.fire({
            icon: 'error',
            title: 'Error al cargar los desembolsos',
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (data.status === 'success') {
          const respuesta = data.listDesembolsos;

          this.montoDisponible = parseFloat(data.sumaDesembolsos);
          const liquidacionesPorDesembolso = await Promise.all(
            respuesta.map(async (desembolso) => {
              const liquidaciones = await this.getLiquidaciones(desembolso.id);
              const gastos = await this.getGastos(desembolso.id);
              return {
                ...desembolso,
                liquidaciones,
                gastos,
              };
            })
          );

          const listDesembolsos = liquidacionesPorDesembolso.map((ele) => {
            const listTotGastos = this.calcularTotales(ele.gastos);

            if (ele.nameDocI === '' && ele.nameDocII === '') {
              this.documentacionOk = true;
            } else {
              this.documentacionOk = false;
            }

            const newEle = { ...ele };

            if (ele.fecha_desembolso === '' && ele.aprobado === false) {
              newEle.isActive = true; // Marcar el elemento como activo
            } else {
              newEle.isActive = false;
            }
            const totalGastos = listTotGastos.totalGeneral || 0;
            const montoDesembolsar = ele.monto - totalGastos;

            return { ...newEle, totalGastos, montoDesembolsar, listTotGastos };
          });

          this.desembolsosCredito = listDesembolsos;

          const liquiActiva = listDesembolsos.filter((elem) => elem.isActive);
          if (liquiActiva.length > 0) {
            this.ingresarMontoAprbado = true;
            this.idDesembolso = liquiActiva[0].id; // Asignar el id al componente
            this.monto = liquiActiva[0].monto;

            this.honorarios = false;
            if (liquiActiva[0].numeroDesembolso === 1) {
              this.totalAprobado = this.montoAprobado;

              this.honorarios = true;
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    reRender(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData(); // Call your data fetching function
        this.calcularTotales();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style>
.my-table {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.my-table th,
.my-table td {
  padding: 10px;
  border: 1px solid #d32727;
  text-align: left;
}

.my-table th {
  font-weight: bold;
}

.my-table .text-right {
  text-align: right;
}

.table-header {
  font-weight: bold;
  text-align: left;
  padding: 10px; /* Adjust padding for desired spacing */
}

.table-cell {
  text-align: left;
  padding: 5px; /* Adjust padding for desired spacing */
}
.table-btn {
  text-align: right;
  padding: 5px; /* Adjust padding for desired spacing */
}
.centered-table {
  display: flex;
  justify-content: center;
}
.disabled-table {
  opacity: 0.5;
}
</style>
